import { format } from 'date-fns';
const DataReceiveStatusTable = ({ receiveData, setReceiveData }) => {
  const columns = [
    {
      title: 'Ship name',
      field: 'ship_name',
      width: '18%',
    },
    {
      title: 'Receive Date & Time',
      field: 'receive_date',
      width: '18%',
    },
    {
      title: 'VDR',
      field: 'vdr',
      width: '8%',
    },
    {
      title: 'Data \nLogger',
      field: 'datalogger',
      width: '8%',
    },
    {
      title: 'Remarks',
      field: 'remarks',
      width: '32%',
    },
    {
      title: 'UpDate',
      field: 'update_date',
      width: '16%',
    },
  ];
  const handleRemarkChange = (e, shipName, receiveDate) => {
    const newRemark = e.target.value;
    setReceiveData((prevData) =>
      prevData.map((data) =>
        data.ship_name === shipName && data.receive_date === receiveDate
          ? { ...data, remarks: newRemark }
          : data
      )
    );
  };

  return (
    <div className="table-area" style={{ width: '95%', height: '430px' }}>
      <table className="receive-table child-table">
        <thead>
          <tr>
            {columns.map((column) => {
              return (
                <th
                  key={column.field}
                  style={{ whiteSpace: 'pre-wrap', width: column.width }}
                >
                  {column.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {receiveData?.map((rowData, i) => {
            return (
              <tr key={rowData.ship_name + i}>
                {rowData.vdr === 'X' || rowData.data_logger === 'X' ? (
                  <td style={{ backgroundColor: 'red', color: 'white' }}>
                    {rowData.ship_name}
                  </td>
                ) : rowData.receive_date === '' ||
                  rowData.data_alarm_flg === '1' ? (
                  <td style={{ backgroundColor: 'orange', color: 'white' }}>
                    {rowData.ship_name}
                  </td>
                ) : (
                  <td>{rowData.ship_name}</td>
                )}
                {rowData.receive_date === '' ? (
                  <td style={{ backgroundColor: 'orange', color: 'white' }}>
                    {rowData.receive_date}
                  </td>
                ) : rowData.data_alarm_flg === '1' ? (
                  <td style={{ backgroundColor: 'orange', color: 'white' }}>
                    {rowData.receive_date}
                  </td>
                ) : (
                  <td>{rowData.receive_date}</td>
                )}
                <td
                  style={{
                    backgroundColor: rowData.vdr === 'X' ? 'red' : '',
                    color: rowData.vdr === 'X' ? 'white' : '',
                  }}
                >
                  {rowData.vdr}
                </td>
                <td
                  style={{
                    backgroundColor: rowData.data_logger === 'X' ? 'red' : '',
                    color: rowData.data_logger === 'X' ? 'white' : '',
                  }}
                >
                  {rowData.data_logger}
                </td>
                <td>
                  <input
                    name="remarks"
                    className="textbox-area"
                    value={rowData.remarks}
                    onChange={(e) =>
                      handleRemarkChange(
                        e,
                        rowData.ship_name,
                        rowData.receive_date
                      )
                    }
                  />
                </td>
                {rowData.update_date ? (
                  <td>{format(rowData.update_date, 'yyyy-MM-dd HH:mm:ss')}</td>
                ) : (
                  <td></td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default DataReceiveStatusTable;
