import { useState, useEffect } from 'react';
import axios from 'axios';
import ReturnMenuButton from '../components/ReturnMenuButton';
import AlarmRadioButton from '../components/AlarmRadioButton';
import DataReceiveStatusTable from '../components/DataReceiveStatusTable';
import CompanySelectBox from '../components/CompanySelectBox';
import ShipSelectBox from '../components/ShipSelectBox';
import SearchButton from '../components/SearchButton';
import UpdateButton from '../components/UpdateButton';

const FMSW040 = () => {
  const [receiveData, setReceiveData] = useState([]);
  const [originalReceiveData, setOriginalReceiveData] = useState([]); // 元のデータを保存する
  const [alarm, setAlarm] = useState('All');
  const [shipCompanyList, setshipCompanyList] = useState([]);
  const [shipList, setshipList] = useState([]);
  const [company, setCompany] = useState([]);
  const [ship, setShip] = useState([]);
  const [isSearched, setIsSearched] = useState(false);

  const defaultSelected = { value: '', label: 'All Selected' };

  useEffect(() => {
    axios
      .get(window.location.origin + '/api/FMSW040/getCompanyShipList')
      .then((res) => {
        setshipCompanyList([defaultSelected, ...res.data.shipcompany]);
        setCompany([defaultSelected]);
        setshipList([defaultSelected, ...res.data.ship]);
        setShip([defaultSelected]);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    const conds = {
      imo_no: ship ? ship.map((e) => e.value) : [],
      shipcompany_id: company ? company.map((e) => e.value) : [],
      alarm: alarm,
    };
    axios
      .post(window.location.origin + '/api/FMSW040/getReceiveData', conds)
      .then((res) => {
        setReceiveData(res.data);
        setOriginalReceiveData(res.data);
        setIsSearched(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [isSearched]);

  // Ship Company 選択時
  const handleShipCompanySelect = (selectedOptions, actionMeta) => {
    // 全選択オプションが含まれる場合
    if (
      actionMeta.action === 'select-option' &&
      selectedOptions.some((opt) => opt.value === '')
    ) {
      setCompany([actionMeta.option]);
    } else {
      Array.isArray(selectedOptions)
        ? setCompany(selectedOptions)
        : setCompany([selectedOptions]);
    }
  };

  // Ship 選択時
  const handleShipSelect = (selectedOptions, actionMeta) => {
    // 全選択オプションが含まれる場合
    if (
      actionMeta.action === 'select-option' &&
      selectedOptions.some((opt) => opt.value === '')
    ) {
      setShip([actionMeta.option]);
    } else {
      Array.isArray(selectedOptions)
        ? setShip(selectedOptions)
        : setShip([selectedOptions]);
    }
  };

  const handleUpdate = () => {
    // 更新する前にRemarksの変更がないか確認
    const updatedData = receiveData.filter(
      (data, index) => data.remarks !== originalReceiveData[index].remarks
    );
    if (updatedData.length > 0) {
      var conds = new Array(updatedData.length);
      for (let i = 0; i < updatedData.length; i++) {
        const shipcompanyId = updatedData[i].shipcompany_id;
        const shipId = updatedData[i].ship_id;
        const newRemarks = updatedData[i].remarks;
        const cond = {
          shipcompany_id: shipcompanyId,
          ship_id: shipId,
          remarks: newRemarks,
        };
        conds[i] = cond;
      }
      axios
        .post(window.location.origin + '/api/FMSW040/updateRemarks', conds)
        .then((res) => {
          if (res.data > 0) {
            setIsSearched(true);
          } else {
            alert('Update Error');
          }
        })
        .catch(() => {
          alert('Update Error');
        });
    }
  };
  return (
    <>
      <div>
        <ReturnMenuButton />
        <div
          className="child-row groupbox-container"
          style={{ height: '560px' }}
        >
          <div className="groupbox-title">
            <h5 className="groupbox-label">Data Receive Status</h5>
          </div>
          <div className="childgroupbox-content">
            <div className="up-row-division ">
              <CompanySelectBox
                shipCompanyList={shipCompanyList}
                company={company}
                handleShipCompanySelect={handleShipCompanySelect}
              />
              <AlarmRadioButton alarm={alarm} setAlarm={setAlarm} />
              <ShipSelectBox
                shipList={shipList}
                ship={ship}
                handleShipSelect={handleShipSelect}
              />
              <SearchButton setIsSearched={setIsSearched} />
              <UpdateButton onUpdate={handleUpdate} />
            </div>
            <DataReceiveStatusTable
              receiveData={receiveData}
              setReceiveData={setReceiveData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default FMSW040;
