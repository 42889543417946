import UpdateDateTableHeader from './UpdateDateTableHeader';
import SelectYearMonthList from './SelectYearMonthList';
import ViewButton from './ViewButton';
import { format } from 'date-fns';

const NoonDataTable = ({
  noonData,
  sortTable,
  isUpdateSort,
  yearMonth,
  setYearMonth,
  officer,
}) => {
  const officer_columns =
    officer == 'DECK'
      ? [
          {
            title: 'GMT',
            field: 'gmt',
          },
          {
            title: 'Latitude',
            field: 'lat',
          },
          {
            title: 'Longitude',
            field: 'lon',
          },
        ]
      : [
          {
            title: 'Sailing Mode',
            field: 'sailing_mode',
            visible: true,
          },
        ];
  const columns = [
    {
      title: 'Date',
      field: 'date',
    },
    ...officer_columns,
    {
      title: 'Update Date\n(Sort OFF)',
      field: 'update_date',
    },
    {
      title: 'View',
      field: 'view',
    },
  ];

  // 年月選択リスト作成
  const selectOptions = [];
  const selectYearMonth = new Date(yearMonth);
  const beforeYearMonth = parseInt(
    import.meta.env.VITE_NOON_LIST_YEARMONTH_BEFORE
  );
  const afterYearMonth = parseInt(
    import.meta.env.VITE_NOON_LIST_YEARMONTH_AFTER
  );
  for (let i = -beforeYearMonth; i <= afterYearMonth; i++) {
    const date = new Date(
      selectYearMonth.getFullYear(),
      selectYearMonth.getMonth() + i
    );
    selectOptions.push(
      `${date.getFullYear()}/${String(date.getMonth() + 1).padStart(2, '0')}`
    );
  }

  return (
    <>
      <SelectYearMonthList
        options={selectOptions}
        yearMonth={yearMonth}
        setYearMonth={setYearMonth}
      />
      <div className="table-area">
        <table className="list-table">
          <thead>
            <tr>
              {columns.map((column) => {
                return column.field == 'update_date' ? (
                  <UpdateDateTableHeader
                    isUpdateSort={isUpdateSort}
                    sortTable={sortTable}
                    key={column.field}
                  />
                ) : (
                  <th key={column.field} style={{ whiteSpace: 'pre-wrap' }}>
                    {column.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {noonData?.map((rowData) => {
              return (
                <tr key={rowData.noonreport_id + rowData.history_id}>
                  {columns.map((column) => {
                    return column.field == 'view' ? (
                      <td key={column.field}>
                        <ViewButton
                          url={'/FMSW011'}
                          params={{
                            noonId: rowData.noonreport_id,
                            historyId: rowData.history_id,
                            officer,
                            yearMonth,
                          }}
                        />
                      </td>
                    ) : column.field == 'date' ? (
                      <td key={column.field}>
                        {rowData[column.field]
                          ? format(rowData[column.field], 'yyyy-MM-dd')
                          : ''}
                      </td>
                    ) : column.field == 'gmt' ||
                      column.field == 'update_date' ? (
                      <td key={column.field}>
                        {rowData[column.field]
                          ? format(rowData[column.field], 'yyyy-MM-dd HH:mm')
                          : ''}
                      </td>
                    ) : (
                      <td key={column.field}>{rowData[column.field]}</td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default NoonDataTable;
