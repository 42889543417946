import Select, { components } from 'react-select';

const CompanySelectBox = ({
  shipCompanyList,
  company,
  handleShipCompanySelect,
}) => {
  const customStyles = {
    valueContainer: (provided) => ({
      ...provided,
      display: 'flex',
      flexWrap: 'nowrap',
    }),
  };

  const ValueContainer = ({ children, ...props }) => {
    const { selectProps } = props;
    const selectedCount = selectProps.value.length;

    if (selectedCount > 2) {
      return (
        <components.ValueContainer {...props}>
          {selectedCount} selected
        </components.ValueContainer>
      );
    } else if (
      Array.isArray(selectProps.value) &&
      selectedCount &&
      selectProps.value[0].value == ''
    ) {
      return (
        <components.ValueContainer {...props}>
          All selected
        </components.ValueContainer>
      );
    } else if (
      !Array.isArray(selectProps.value) &&
      selectProps.value.value == ''
    ) {
      return (
        <components.ValueContainer {...props}>
          All selected
        </components.ValueContainer>
      );
    }

    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  };

  return (
    <div>
      <label>Company</label>
      <div className="select-box">
        <Select
          options={shipCompanyList}
          isMulti={true}
          value={company}
          onChange={handleShipCompanySelect}
          styles={customStyles}
          components={{ ValueContainer }}
          placeholder={'All Selected'}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
        ></Select>
      </div>
    </div>
  );
};

export default CompanySelectBox;
