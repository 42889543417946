import UpdateDateTableHeader from './UpdateDateTableHeader';
import ViewButton from './ViewButton';
import { format } from 'date-fns';

const ArrDepDataTable = ({ arrDepData, sortTable, isUpdateSort, officer }) => {
  const columns = [
    {
      title: 'Date & Time',
      field: 'date_and_time',
    },
    {
      title: 'Port Name',
      field: 'port_name',
    },
    {
      title: 'Arrival /\nDeparture',
      field: 'departure_arrival',
    },
    {
      title: 'Update Date\n(Sort OFF)',
      field: 'update_date',
    },
    {
      title: 'View',
      field: 'view',
    },
  ];

  return (
    <div className="table-area">
      <table className="list-table">
        <thead>
          <tr>
            {columns.map((column) => {
              return column.field == 'update_date' ? (
                <UpdateDateTableHeader
                  isUpdateSort={isUpdateSort}
                  sortTable={sortTable}
                  key={column.field}
                />
              ) : (
                <th key={column.field} style={{ whiteSpace: 'pre-wrap' }}>
                  {column.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {arrDepData?.map((rowData) => {
            return (
              <tr key={rowData.arrival_departure_id + rowData.history_id}>
                {columns.map((column) => {
                  return column.field == 'view' ? (
                    <td key={column.field}>
                      <ViewButton
                        url={'/FMSW031'}
                        params={{
                          arrDepId: rowData.arrival_departure_id,
                          historyId: rowData.history_id,
                          officer,
                        }}
                      />
                    </td>
                  ) : column.field == 'date_and_time' ||
                    column.field == 'update_date' ? (
                    <td key={column.field}>
                      {rowData[column.field]
                        ? format(rowData[column.field], 'yyyy-MM-dd HH:mm')
                        : ''}
                    </td>
                  ) : (
                    <td key={column.field}>{rowData[column.field]}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ArrDepDataTable;
